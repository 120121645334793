import '../../App.css';
import './services.css';
//import TrollImage from '../../images/troll.png'
import StockImage from '../../images/studystock.png';
import StockTutor from '../../images/stocktutor.png'
export const Services = () => {
    const serviceBlocks = [
        { number: "01", title: "Study Sessions", description: "Learn from your peers! Our representatives lead study sessions, where students work together through concepts learned in class. ", borderColor: "red" },
        { number: "02", title: "Review Material", description: "Find material for your classes! We create many study resources such as slideshow presentations, practice tests, and more.", borderColor:"red" },
        { number: "03", title: "One-on-One tutoring", description: "Need help? Our one-on-one tutoring sessions can provide a more personalized learning and study experience.", borderColor:"red" }
    ]; 
    
    return(
    
    <div>
            <div className="parent-centered-container">
                <div className="centered-container">
                    <h1>Our Services</h1>
                    <p className="para">At ACE, our goal is to prepare students to do their best. <br></br>Our three main services are: study sessions,
                    review material, and one-on-one tutoring.</p>
                </div>
            </div>
        <br /><br />
        <div className="service-grid">
            <div className="horizontal-service-container">
                {serviceBlocks.map((block, index) => (
                     <div key={index} className="service-block" 
                     style={{ borderColor: block.borderColor, borderStyle: 'solid', borderWidth: '1px'}}>
                     <h2 className="gradient-text-ace">{block.number}</h2>
                     <h2 className="title-color">{block.title}</h2>
                     <p>{block.description}</p>
                 </div>
                ))}
            </div>
        </div>
        <br />
        <div className="home-container">
            <div className="content-grid">
                <div className="image-container">
                    <img src={StockImage} alt="kaytra" className="homeimage"/>
                    <br></br><br></br>
                </div>

                <div className="text-container">
                    <h3 className="study-classes">Study <br /> <span className="all-in">Sessions</span></h3>
             
                </div>
            </div>
        </div>

         <div className="home-container">
            <div className="content-grid">
                <div className="text-container">
                    <h3 className="study-classes">Review <br /> <span className="all-in">Material</span></h3>
                </div>
                
                <div className="image-container">
                    <iframe src="https://docs.google.com/presentation/d/e/2PACX-1vTPWJxb9Y3F-KiKhychJ8wngAPxWvJbjkg5oT7LeQT9dBdxvVKgY3G6cXHxx_YZnomK0D3NhHziPZ2c/embed?start=false&loop=false&delayms=3000" title="acereview" frameBorder={0} width={480} height={299} allowFullScreen={true} />
                    <br></br><br></br>
                </div>
         
            </div>
         </div>

         <div className="home-container">
            <div className="content-grid">
                <div className="image-container">
                    <img src={StockTutor} alt="tutoring" className="homeimage"/>
                    <br></br><br></br>
                </div>

                <div className="text-container">
                    <h3 className="study-classes">One-on-One <br /> <span className="all-in">Tutoring</span></h3>
                </div>
            </div>
         </div>

    </div>
    );
}