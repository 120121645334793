// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getAuth, GoogleAuthProvider} from "firebase/auth";
//import { getAnalytics } from "firebase/analytics";
import {getFirestore} from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBccPJ1jJc41QLenxRkfLQrUS5JJRE03co",
  authDomain: "ace-website-f0308.firebaseapp.com",
  projectId: "ace-website-f0308",
  storageBucket: "ace-website-f0308.appspot.com",
  messagingSenderId: "36697956832",
  appId: "1:36697956832:web:8f7438e3ce0cd124f0cb85",
  measurementId: "G-WT01HVBVHJ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();
export const db = getFirestore(app);
//const analytics = getAnalytics(app);