import '../../App.css';
export const StartChapter = () => {
    return(
        <div>
            <h1>Start a Chapter</h1>
            <p>Do you want to join the academic community of ACE? Let's start with you!</p>
            <p>If you would like to open a chapter, please complete the following form by clicking <a href="https://forms.gle/Edr4fRU8mwSn494M8" target="/blank" className="hype">here.</a></p>
            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSe4y_aC-tED45xGPN4TGTH2habhko20OGwQr7c-Zt8hQQ0MGQ/viewform?embedded=true" width={640} height={1680} frameBorder={0} marginHeight={0} marginWidth={0} title="aceform">Loading…</iframe>
        </div>
    );
}