import {useForm} from 'react-hook-form'
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {addDoc, collection} from 'firebase/firestore';
import {auth, db} from '../../config/firebase';
import {useAuthState} from "react-firebase-hooks/auth";
import {useNavigate} from 'react-router-dom'
import '../../App.css';
//import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
//import {useState} from 'react';
import './contact.css'



interface CreateFormData {
    name: string;
    email: string;
    message: string;

}


export const CreateContactForm = () => {
    const [user] = useAuthState(auth);
    const navigate = useNavigate();
    
    const schema = yup.object().shape({
        name: yup.string().required("Your name is required"),
        email: yup.string().email("Your email must be a valid email.").required("Your email is required"),
        message: yup.string().required("Please fill out a message!")

    });

    const{register, handleSubmit, formState: {errors},} = useForm<CreateFormData>({
        resolver: yupResolver(schema),
    })

    const postsRef = collection(db, "contactmessages")

    const onCreatePost = async (data: CreateFormData) => {
        await addDoc(postsRef, {
            name: data.name,
            email: data.email,
            message: data.message,
            username: user?.displayName,
            userId: user?.uid,
        });
        navigate("/home")
        
    }

    

    return(
        <div>
        <h1><span className="gradient-text-ace">Contact Us!</span></h1>
    <form onSubmit={handleSubmit(onCreatePost)} className="contact-container">
      <div className="input-group">
        <input 
          placeholder="Name" 
          {...register("name", { required: "Name is required" })} 
          className="input-field"
        />
        <input 
          placeholder="Email" 
          {...register("email", { 
            required: "Email is required", 
            pattern: { value: /^\S+@\S+$/i, message: "Invalid email address" } 
          })} 
          className="input-field"
        />
      </div>
      {errors.name && <p className="error-message">{errors.name.message}</p>}
      {errors.email && <p className="error-message">{errors.email.message}</p>}
      <textarea 
        placeholder="Enter your message here!" 
        {...register("message", { required: "Message is required" })} 
        className="textarea-field"
      />
      {errors.message && <p className="error-message">{errors.message.message}</p>}
      <input type="submit" className="submit-button" value="Send" />
    </form>
        <br></br>
        </div>
    );
}


/**<form onSubmit={handleSubmit(onCreatePost)} className="contact-container">
      <div className="input-group">
        <input placeholder="Name" {...register("name", { required: "Name is required" })} />
        <input placeholder="Email" {...register("email", { required: "Email is required", pattern: { value: /^\S+@\S+$/i, message: "Invalid email address" } })} />
      </div>
      {errors.name && <p className="error-message">{errors.name.message}</p>}
      {errors.email && <p className="error-message">{errors.email.message}</p>}

      <textarea 
        placeholder="Enter your message here!" 
        {...register("message", { required: "Message is required" })} 
      />
      {errors.message && <p className="error-message">{errors.message.message}</p>}
      <input type="submit" className="submitForm" value="Send" />
    //</form>**/