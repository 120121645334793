import React, { useState } from 'react';
import { ChevronDown, Plus } from 'lucide-react';

interface CustomDropdownProps {
  options: string[];
  onSelect: (option: string) => void;
}


const CustomDropdown = ({ options, onSelect }: CustomDropdownProps): JSX.Element => {
  const [selectedOption, setSelectedOption] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [newOption, setNewOption] = useState<string>('');
  const [allOptions, setAllOptions] = useState<string[]>(options);

  const handleSelect = (option: string): void => {
    if (option === 'create-new') {
      setSelectedOption('');
    } else {
      setSelectedOption(option);
      onSelect(option);
    }
    setIsOpen(false);
  };

  const handleNewOptionSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    if (newOption.trim() !== '') {
      setAllOptions([...allOptions, newOption]);
      setSelectedOption(newOption);
      onSelect(newOption);
      setNewOption('');
      setIsOpen(false);
    }
  };

  return (
    <div className="relative w-64">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full p-2 text-left bg-white border border-gray-300 text-black rounded-md flex justify-between items-center"
      >
        {selectedOption || 'Select an option'}
        <ChevronDown className={`transition-transform ${isOpen ? 'rotate-180' : ''}`} />
      </button>
      {isOpen && (
        <div className="absolute w-full mt-1 bg-white border text-black border-gray-300 rounded-md shadow-lg">
          {allOptions.map((option) => (
            <div
              key={option}
              onClick={() => handleSelect(option)}
              className="p-2 hover:bg-gray-100 cursor-pointer"
            >
              {option}
            </div>
          ))}
          <div
            onClick={() => handleSelect('create-new')}
            className="p-2 hover:bg-gray-100 cursor-pointer flex items-center"
          >
            <Plus className="mr-2" /> Create new option
          </div>
          {selectedOption === '' && (
            <form onSubmit={handleNewOptionSubmit} className="p-2">
              <input
                type="text"
                value={newOption}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewOption(e.target.value)}
                placeholder="Enter new option"
                className="w-full p-1 border border-gray-300 rounded"
              />
              <button
                type="submit"
                className="mt-2 w-full p-1 bg-blue-500 text-black rounded hover:bg-blue-600"
              >
                Add Option
              </button>
            </form>
          )}
        </div>
      )}
    </div>
  );
};

export const CustomDropdownPage = (): JSX.Element => {
  const [selectedOption, setSelectedOption] = useState<string>('');
  const initialOptions: string[] = ['Option 1', 'Option 2', 'Option 3'];

  const handleSelect = (option: string): void => {
    setSelectedOption(option);
    console.log('Selected option:', option);
  };

  return (
    <div className="p-4">
      <h1 className="text-2xl mb-4">Custom Dropdown Example</h1>
      <CustomDropdown options={initialOptions} onSelect={handleSelect} />
      <p className="mt-4">Selected option: {selectedOption}</p>
    </div>
  );
};


/**interface Image {
    src: string;
    alt: string;
  }
  
  const ImageGrid = () => {
    const [images, setImages] = useState<Image[]>(Array(9).fill({ src: '/api/placeholder/100/100', alt: 'Placeholder' }));
  
    const handleImageChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const newImages = [...images];
          newImages[index] = { src: e.target?.result as string, alt: file.name };
          setImages(newImages);
        };
        reader.readAsDataURL(file);
      }
    };
  
    return (
      <div className="grid grid-cols-3 gap-4 bg-black p-4">
        {images.map((image, index) => (
          <div key={index} className={`relative ${index === 4 ? 'col-span-3' : ''}`}>
            <img
              src={image.src}
              alt={image.alt}
              className="w-full h-full object-cover rounded-lg"
            />
            <input
              type="file"
              accept="image/*"
              onChange={(e) => handleImageChange(index, e)}
              className="absolute inset-0 opacity-0 cursor-pointer"
            />
            <div className="absolute inset-0 bg-purple-500 bg-opacity-20 rounded-lg pointer-events-none" />
          </div>
        ))}
      </div>
    );
  };
  
  export default ImageGrid;**/



/**export const CustomDropdown = ({ options, onSelect }: CustomDropdownProps): JSX.Element => {
  const [selectedOption, setSelectedOption] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [newOption, setNewOption] = useState<string>('');
  const [allOptions, setAllOptions] = useState<string[]>(options);

  const handleSelect = (option: string): void => {
    if (option === 'create-new') {
      setSelectedOption('');
    } else {
      setSelectedOption(option);
      onSelect(option);
    }
    setIsOpen(false);
  };

  const handleNewOptionSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    if (newOption.trim() !== '') {
      setAllOptions([...allOptions, newOption]);
      setSelectedOption(newOption);
      onSelect(newOption);
      setNewOption('');
      setIsOpen(false);
    }
  };

  return (
    <div className="relative w-64">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full p-2 text-left bg-white border border-gray-300 rounded-md flex justify-between items-center"
      >
        {selectedOption || 'Select an option'}
        <ChevronDown className={`transition-transform ${isOpen ? 'rotate-180' : ''}`} />
      </button>
      {isOpen && (
        <div className="absolute w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg">
          {allOptions.map((option) => (
            <div
              key={option}
              onClick={() => handleSelect(option)}
              className="p-2 hover:bg-gray-100 cursor-pointer"
            >
              {option}
            </div>
          ))}
          <div
            onClick={() => handleSelect('create-new')}
            className="p-2 hover:bg-gray-100 cursor-pointer flex items-center"
          >
            <Plus className="mr-2" /> Create new option
          </div>
          {selectedOption === '' && (
            <form onSubmit={handleNewOptionSubmit} className="p-2">
              <input
                type="text"
                value={newOption}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewOption(e.target.value)}
                placeholder="Enter new option"
                className="w-full p-1 border border-gray-300 rounded"
              />
              <button
                type="submit"
                className="mt-2 w-full p-1 bg-blue-500 text-white rounded hover:bg-blue-600"
              >
                Add Option
              </button>
            </form>
          )}
        </div>
      )}
    </div>
  );
};**/