import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import './App.css';
import {Main} from './pages/main/main';
import {Login} from "./pages/login";
import {Navbar} from "./components/navbar";
import {CreatePost} from './pages/create-post/create-post';
import {AboutUs} from './pages/about-us/about-us';
import { StartChapter } from './pages/start-chapter/start-chapter';
import { Home } from './pages/home/home';
//import {Footer} from './components/footer';
import {Footer2} from './components/footer2';
import {CreateContactForm} from './pages/contact-us/contact-us';
import {Services} from './pages/services/services';
import {ReportIncident} from './pages/report-incident/report-incident'
import {CustomDropdownPage} from './pages/test/test'


function App() {
  
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/posts" element={<Main />}/>
          <Route path="/login" element={<Login />} />
          <Route path="/createpost" element={<CreatePost />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/startchapter" element={<StartChapter />} />
          <Route path="/contactus" element={<CreateContactForm />} />
          <Route path="/services" element={<Services />} />
          <Route path="/reportincident" element={<ReportIncident />} />
          <Route path="/test" element={<CustomDropdownPage />} />        
        </Routes>
      </Router>
   
      <Footer2 />
    </div>
  );
}

export default App;
