import './footer.css'
import LogoImage from '../images/ace.png'

export const Footer2 = () => (
    <div className="content">
        <script src="https://kit.fontawesome.com/3150cf19f5.js" crossOrigin="anonymous"></script>
    <footer className="footer">
      <div className="footer__container">
        <div className="footer__content">
          <div className="footer__section footer__section--main">
            <img src={LogoImage} alt="ACE Logo" className="footer__logo" width="1000" height="1000" />
            <h1 className="custom-heading">
            <span className="gradient-text-ace">A student-based approach.</span>
            </h1>
          </div>
  
         
          <div className="footer__section">
            <h3 className="footer__heading">Useful Links</h3>
            <ul className="footer__list">
              <li><a href="/">Home</a></li>
              <li><a href="/services">Services</a></li>
              <li><a href="/contactus">Contact Us</a></li>
              <li><a href="/aboutus">About Us</a></li>
              <li><a href="/startchapter">Start a Chapter</a></li>
            </ul>
          </div>
  
          
          <div className="footer__section">
            <h3 className="footer__heading">Contact Us</h3>
            <ul className="footer__list">
              <li><a href="/contactus">Contact Us</a></li>
              <li><a href="mailto:contact@acechapters.org" rel="noreferrer" target="_blank">contact@acechapters.org</a></li>
              <li><a href="/reportincident">Incident Report</a></li>
            </ul>
          </div>
  
          
          <div className="footer__section">
            <h3 className="footer__heading">Socials</h3>
            <ul className="footer__list">
              <li><a href="https://www.linkedin.com/company/acechapters" target="_blank" rel="noreferrer">LinkedIn</a></li>
              <li><a href="https://www.instagram.com/acechapters/" target="_blank" rel="noreferrer">Instagram</a></li>
              <li><a href="https://www.twitter.com" target="_blank" rel="noreferrer">X (Twitter)</a></li>
              <li><a href="https://www.tiktok.com" target="_blank" rel="noreferrer">TikTok</a></li>
            </ul>
          </div>
        </div>
  
        {/* Copyright */}
        <div className="footer__copyright">
          <p>&copy;{new Date().getFullYear()} ACE. All rights reserved. ACE is a registered 501(c)(3) organization. </p>
        </div>
      </div>
    </footer>
    </div>
  );
  